import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/css/app.css';
import './assets/css/custom.css';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';

import Dashboard from './pages/RetrainChatbot';
import AddDocuments from './pages/Documents/AddDocuments';
import AddWebsite from './pages/WebsiteLinks/AddWebsite';
import WebsiteConfigurations from './pages/WebsiteConfigurations';
import AudioLanguage from './pages/AudioLanguage/AddAudioLanguage';
import ChatHistory from './pages/ChatHistory';
import ChoosePosition from './pages/ChoosePosition';
import AvatarSettings from './pages/AvatarSettings';
import Analytics from './pages/Analytics';
import UserProfile from './pages/UserProfile';
import Test from './pages/Test';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import ColorSettings from './pages/ColorSettings';
import ImproveQuestions from './pages/QuestionImprovement/AddImproveQuestions';
import ImproveAnswer from './pages/AnswerImprovement/ImproveAnswer';
import LogoutPage from './pages/LogoutPage';
import Pronoun from './pages/Pronoun/AddPronoun';
import AddSitemap from './pages/Sitemaps/AddSitemap';
import ListUserFeedback from './pages/UserFeedback.js';
import Help from './info-pages/Help.js';
import Support from './info-pages/Support.js';
import Privacy from './info-pages/Privacy.js';
import Terms from './info-pages/Terms.js';
import Billing from './pages/Billing/Billing.js';
import InvoiceHistory from './pages/Billing/InvoiceHistory.js';
import Pricing from './pages/Billing/Pricing.js';
import SubscriptionHistory from './pages/Billing/SubscriptionHistory.js';
import RetrainChatbot from './pages/RetrainChatbot.js';
import LLMSettings from './pages/LLMSettings.js';

import React, { useState } from 'react';
// import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import {  BrowserRouter,  Link,  Route,  Routes,} from "react-router-dom";

function App() {

  const isLoggedIn = localStorage.getItem('access_token');

  // State to manage the collapsed state of the sidebar
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Function to toggle the collapsed state
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  
  return (    
    <BrowserRouter>
      <Routes>
        <Route path="/Login" exact element={<SignIn />} />
        <Route path="/SignUp" exact element={<SignUp />} />
        <Route path="/reset-password" exact element={<ResetPassword />} />
        <Route path="/log-out" exact element={<LogoutPage />} />

      </Routes>
      {isLoggedIn ? (
        <div className="wrapper">
          <Sidebar isCollapsed={isCollapsed} />
          <div className="main">
            <Header toggleSidebar={toggleSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/Dashboard" element={<Dashboard />} />              
              <Route path="/add-documents" element={<AddDocuments />} />
              <Route path="/add-website" element={<AddWebsite />} />
              <Route path="/configuration" exact element={<WebsiteConfigurations />} />
              <Route path="/add-sitemap" element={<AddSitemap />} />
              <Route path="/audio" element={<AudioLanguage />} />
              <Route path="/chat-history" element={<ChatHistory />} />
              <Route path="/choose-position" element={<ChoosePosition />} />
              <Route path="/avatar" element={<AvatarSettings />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/user-profile" element={<UserProfile />} />
              <Route path="/Test" element={<Test />} />
              <Route path="/thumbnail-color" element={<ColorSettings />} />
              <Route path="/question-improvements" element={<ImproveQuestions />} />
              <Route path="/answers-improvements" element={<ImproveAnswer />} />
              <Route path="/Pronoun" element={<Pronoun />} />
              <Route path="/user-feedback" element={<ListUserFeedback />} />

              <Route path="/help" element={<Help />} /> 
              <Route path="/support" element={<Support />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/Billing" element={<Billing />} />
              <Route path="/Billing/Billing-History" element={<InvoiceHistory />} />
              <Route path="/Billing/Pricing" element={<Pricing />} />
              <Route path="/Billing/Subscription" element={<SubscriptionHistory />} />
              <Route path="/RetrainChatbot" element={<RetrainChatbot />} />
              <Route path="/LLMSettings" element={<LLMSettings />} />
            </Routes>
            <Footer />
          </div>
        </div>
      ) : (
        // <Redirect to="/Login" />
        <Routes>
          {/* <Route path="/Login" exact element={<SignIn />} /> */}
          <Route path="/" element={<SignIn />} />
        </Routes>
      )}
      
    </BrowserRouter>
  );
}

export default App;
