import React, { useEffect, useState } from "react";
import axios from "axios";
import backendURL from "../config"; // Ensure backendURL is your Flask API URL

export default function LLMSETTINGS() {
  const [message, setMessage] = useState("");
  const [prompt, setPrompt] = useState("");
  // const [prompt, setPrompt] = useState(`Goal: You're an AI-trained chatbot to find the relevant answer to the given question.
  
  // Input: Text question.
  
  // Output:
  // Find the relevant answer to the given question from our data. If you do not find the answer in the data, then respond like, 'I didn't find the answers. Please ask another question.'
  // `);

  // Get token from localStorage
  const token = localStorage.getItem('access_token');
  const headers = { Authorization: `Bearer ${token}` };

  // Fetch the LLM settings (prompt) from the API on component mount
  useEffect(() => {
    const fetchLLMSettings = async () => {
      try {
        const response = await axios.get(`${backendURL}/llm-settings`, { headers });
        if (response.data && response.data.prompt_text) {
          setPrompt(response.data.prompt_text);
        }
      } catch (error) {
        setMessage("Error fetching LLM settings");
      }
    };
    fetchLLMSettings();
  }, []);

  const handleSave = async () => {
    try {
      const response = await axios.post(
        `${backendURL}/llm-settings`,
        { prompt_text: prompt }, // Send the prompt in JSON body
        { 
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json' // Ensure content-type is set to JSON
          } 
        }
      );
      if (response.status === 200 || response.status === 201) {
        setMessage("Prompt saved successfully!");
      } else {
        setMessage("Error saving prompt");
      }
    } catch (error) {
      setMessage("Error saving prompt");
    }
  };
  
  


  return (
    <div>
      <main className="content page-website-configurations">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"><strong>LLM</strong> Settings</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-8 col-lg-8">
                      <h5 className="card-title mb-1">
                        Prompt:
                      </h5>
                      <div className="">
                        <textarea
                          type="text"
                          id="prompting"
                          name="prompting"
                          className="form-control"
                          value={prompt}
                          onChange={(e) => setPrompt(e.target.value)}
                          style={{ height: "200px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <br />
                      <button className="btn btn-primary" onClick={handleSave}> Save </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <br />
                      <div id="getError" className={`alert alert-info ${message ? "" : "d-none"}`}>{message}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
